import { Select } from './Select';

export interface FormLimiteCredito {
  cod_finalizadora: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  des_finalizadora: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  val_limite: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
}

export const formLimiteCreditoBlank: FormLimiteCredito = {
  cod_finalizadora: {
    value: {
      value: undefined,
      label: '',
    },
    isInvalid: true,
    isRequired: true,
  },
  des_finalizadora: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  val_limite: {
    value: '',
    isInvalid: true,
    isRequired: true,
  },
};

export interface LimiteCredito {
  id: string | number | undefined;
  des_finalizadora: string;
  val_limite: string;
}
