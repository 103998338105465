import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  Select,
  Finalizadora,
  FormLimiteCredito,
} from '~/pages/RegraDeEmpresa/protocols';
import InputSelect from '~/components/Inputs/InputSelect';
import api from '~/services/api';

import { useRegraEmpresa } from '~/pages/RegraDeEmpresa/RegraDeEmpresaContext';
import InputMoney from '~/components/Inputs/InputMoney';
import { ClearButton, ConfirmButton } from '~/components/Buttons';
import { Container } from './styles';

export const Form: React.FC = () => {
  const [finalizadoras, setfinalizadoras] = React.useState<Select[]>([]);
  const [finalizadorasAtivas, setfinalizadorasAtivas] = React.useState<
    Select[]
  >([]);
  const [inicializado, setInicializado] = useState(false);
  const {
    formRegraEmpresa,
    handleFormRegraEmpresa,
    formLimiteCredito,
    handleFormLimiteCredito,
    handleClearFormLimite,
    formTela: { setValue },
  } = useRegraEmpresa();

  async function getfinalizadoras(cod_grupo: string | number | undefined) {
    const res = await api.get(`/regraempresa/finalizadora/${cod_grupo}`);
    const { data } = res.data;

    const regs = data.map((reg: Finalizadora) => {
      return {
        value: reg.cod_finalizadora,
        label: `${reg.des_finalizadora}`,
        des_finalizadora: reg.des_finalizadora,
      };
    });

    const regsAtivas = data.flatMap((reg: any) => {
      if (reg.flg_inativa !== true) {
        return [
          {
            value: reg.cod_finalizadora,
            label: `${reg.des_finalizadora}`,
            des_finalizadora: reg.des_finalizadora,
          },
        ];
      }
      return [];
    });

    setfinalizadoras(regs);
    setfinalizadorasAtivas(regsAtivas);
  }

  useEffect(() => {
    const cod_grupo = formRegraEmpresa.cod_grupo_empresa.value.value;
    if (cod_grupo) {
      getfinalizadoras(cod_grupo);
    }
  }, [formRegraEmpresa.cod_grupo_empresa.value.value]);

  const onAddFinalizadoraLoja = (): void => {
    if (!validaInputsParaSubmit(formLimiteCredito)) {
      return setInicializado(true);
    }
    setInicializado(false);

    // Função para nao duplicar registros
    const hasExist = formRegraEmpresa.Limites.limites.find(
      (limite) =>
        limite.cod_finalizadora.value.value ===
        formLimiteCredito.cod_finalizadora.value.value,
    );
    if (!hasExist) {
      setValue('limites', [
        ...formRegraEmpresa.Limites.limites,
        formLimiteCredito,
      ]);
      handleFormRegraEmpresa({
        ...formRegraEmpresa,
        Limites: {
          ...formRegraEmpresa.Limites,
          limites: [...formRegraEmpresa.Limites.limites, formLimiteCredito],
        },
      });
      return handleClearFormLimite();
    }
    const data = formRegraEmpresa.Limites.limites.map((limite) => {
      if (
        limite.cod_finalizadora.value.value ===
        formLimiteCredito.cod_finalizadora.value.value
      ) {
        limite = formLimiteCredito;
      }
      return limite;
    });

    setValue('limites', data);
    handleFormRegraEmpresa({
      ...formRegraEmpresa,
      Limites: {
        ...formRegraEmpresa.Limites,
        limites: data,
      },
    });
    return handleClearFormLimite();
  };

  useEffect(() => {
    getfinalizadoras(121);
  }, []);

  const validaInputsParaSubmit = (inputs: FormLimiteCredito): boolean => {
    for (let i = 0; i < Object.keys(inputs).length; i++) {
      if (
        formLimiteCredito[Object.keys(inputs)[i] as keyof FormLimiteCredito]
          .isRequired &&
        formLimiteCredito[Object.keys(inputs)[i] as keyof FormLimiteCredito]
          .isInvalid
      ) {
        return false;
      }
    }
    return true;
  };

  const onClearForm = () => {
    handleClearFormLimite();
    setInicializado(false);
  };

  return (
    <Row>
      <Col sm={12} md={4}>
        <InputSelect
          label="Finalizadora"
          onChange={(value: any) => {
            handleFormLimiteCredito({
              ...formLimiteCredito,
              cod_finalizadora: {
                ...formLimiteCredito.cod_finalizadora,
                value,
                isInvalid: value.value === undefined,
              },
              des_finalizadora: {
                ...formLimiteCredito.des_finalizadora,
                value: value.des_finalizadora,
              },
            });
          }}
          options={finalizadorasAtivas}
          isRequired={formLimiteCredito.cod_finalizadora.isRequired}
          setInvalid={formLimiteCredito.cod_finalizadora.isInvalid}
          iniInicializado={inicializado}
          value={
            finalizadoras.find((item: Select) => {
              return (
                item.value === formLimiteCredito.cod_finalizadora.value.value
              );
            }) ?? {
              value: undefined,
              label: '- Selecione a Finalizadora -',
            }
          }
        />
      </Col>
      <Col sm={12} md={3}>
        <InputMoney
          label="Valor Unit."
          value={formLimiteCredito.val_limite.value}
          min={0}
          placeholder="0,00"
          isNull
          isUndefined
          isRequired={formLimiteCredito.val_limite.isRequired}
          setInvalid={formLimiteCredito.val_limite.isInvalid}
          iniInicializado={inicializado}
          onChange={(value: string) => {
            handleFormLimiteCredito({
              ...formLimiteCredito,
              val_limite: {
                ...formLimiteCredito.val_limite,
                value,
                isInvalid: parseFloat(value) === 0,
              },
            });
          }}
        />
      </Col>
      <Col sm={12} md={5} className="Buttoons">
        <Container>
          <ConfirmButton onClick={onAddFinalizadoraLoja}>
            Confirmar
          </ConfirmButton>
          <ClearButton onClick={onClearForm}>Limpar</ClearButton>
        </Container>
      </Col>
    </Row>
  );
};
