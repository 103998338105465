import styled from 'styled-components';

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  outline: none;

  .MuiDataGrid-columnHeader--moving {
    background: red !important;
  }

  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
`;

export const Container = styled.div`
  .MuiDataGrid-columnHeader--moving {
    background: red !important;
  }
`;
