import React from 'react';
import Separator from '~/components/Separator';
import { Form } from './Form';
import { Table } from './Table';
import { Col, Row } from 'react-bootstrap';

export const LimiteCredito: React.FC = () => {
  return (
    <Row>
      <Col sm={12} md={12}>
        <Separator labelText="Limite Padrão para Créditos" />
        <Form />
        <Table />
      </Col>
    </Row>
  );
};
