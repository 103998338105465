import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  .btnNewDataGrid {
    float: right;
    padding: 8px 8px 8px 8px;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    transition: 0.4s;
    color: #424242;
    &:hover {
      background-color: #f4f5f7;
      color: #000;
    }
    &:focus {
      outline: 0;
    }
  }
`;
