import styled from 'styled-components';
import { TooltipPrimitive } from '@atlaskit/tooltip';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .table-actions-buttons {
    margin-top: 32px;
    float: right;

    button {
      padding: 6px 16px;
      margin-left: 8px;
      border-radius: 6px;
    }
  }
  .btn-action {
    margin-left: 1rem;
  }
  .formula-icon {
    margin-bottom: 5px;
    transition: filter 0.2s;

    &:hover {
      cursor: pointer;
      filter: opacity(0.9);
    }

    @media screen and (max-width: 600px) {
      margin-top: 12px;
    }
  }

  @media screen and (max-width: 991px) {
    .Buttoons {
      .confirm {
        min-width: 100px;
      }
      .clear {
        min-width: 100px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .Buttoons {
      margin-right: 10px;
      margin-top: 5px;
      .confirm {
        min-width: 100px;
      }
      .clear {
        min-width: 100px;
      }
    }
  }

  .input-percent::after {
    content: '%';
    margin-right: 1rem;
  }

  .input-money {
    text-align: right;
  }

  .input-money::before {
    content: 'R$';
    margin-left: 1rem;
  }

  .btnNewDataGrid {
    float: right;
    padding: 8px 8px 8px 8px;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    transition: 0.4s;
    color: #424242;
    &:hover {
      background-color: #f4f5f7;
      color: #000;
    }
    &:focus {
      outline: 0;
    }
  }
`;

export const TooltipBreakline = styled(TooltipPrimitive)`
  white-space: pre-wrap;
  color: white;
  background: rgb(23, 43, 77);
  padding: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
  text-decoration-skip-ink: auto;
`;

export const StyledForm = styled.form`
  /* margin-top: 20px; */
  padding: 20px;
  border-radius: 5px;
  /* display: flex; */
  .inlineField {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: #db1212;
  margin-top: 2px;

  .iconStyle {
    font-size: 12px;
    margin-top: -3px;
    margin-right: 2px;
  }
`;

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  outline: none;

  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
`;
