import { GridRowParams } from '@material-ui/data-grid';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { toast } from 'react-toastify';
import api from '~/services/api';
import Search from '~/components/Search';
import { Container } from './styles';
import FormDefault from '~/components/FormDefault';
import { AbaGeral } from '~/pages/RegraDeEmpresa/abas/geral';
import { AbaSistema } from '~/pages/RegraDeEmpresa/abas/sistema';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import InputSelect from '~/components/Inputs/InputSelect';
import { FormRegraEmpresa, GrupoEmpresa, Select } from './protocols';
import { useRegraEmpresa } from '~/pages/RegraDeEmpresa/RegraDeEmpresaContext';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { AbaFinanceiro } from './abas/Financeiro';
import { LojaContext } from '~/context/loja';

const MySwal = withReactContent(Swal);

const RegraDeEmpresaContent: React.FC = () => {
  const { loja, handleLoja } = useContext(LojaContext);
  const [key, setKey] = useState<any>('geral');
  const [desLojas, setDesLojas] = useState<any[]>([]);
  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [grupoEmpresas, setGrupoEmpresas] = useState<Select[]>([]);
  const disabled = true;

  const {
    formRegraEmpresa,
    handleFormRegraEmpresa,
    isUpdate,
    handleIsUpdate,
    inicializado,
    handleClearForm,
    handleInicializado,
    formTela: { setValue, getValues },
  } = useRegraEmpresa();

  useEffect(() => {
    getGrupos();
  }, [isUpdate]);

  async function getGrupos() {
    if (!isUpdate) {
      const res = await api.get('/regraempresa/grupo/0');

      const { data } = res.data;

      const regs = data.map((reg: GrupoEmpresa) => {
        return {
          value: reg.cod_grupo_empresa,
          label: `${reg.des_grupo_empresa} (${reg.des_lojas})`,
        };
      });
      setGrupoEmpresas(regs);
    } else {
      // grupos parametrizados
      const res = await api.get('/regraempresa/grupo/1');

      const { data } = res.data;

      const regs = data.map((reg: GrupoEmpresa) => {
        return {
          value: reg.cod_grupo_empresa,
          label: `${reg.des_grupo_empresa} (${reg.des_lojas})`,
        };
      });
      setGrupoEmpresas(regs);
    }
  }

  const newData = () => {
    getGrupos();
    handleClearForm();
    setShowSearch(false);
    handleInicializado(false);
    setKey('geral');
    handleIsUpdate(false);
    // setIsUpdate(false);
    // if (grupoEmpresas.length === 0) {
    //   toast.error('Não há empresas para serem parametrizadas.');
    //   setShowSearch(true);
    // } else {
    //   // handleClearForm();
    //   // setShowSearch(false);
    //   // setIsUpdate(false);
    // }
  };

  const onRowClick = async (params: GridRowParams) => {
    const { row } = params;
    const { cod_grupo_empresa, des_lojas } = row;

    const arrDesLojas = des_lojas.split(',');
    setDesLojas(arrDesLojas);
    setKey('geral');
    const res = await api.get(`regraempresa/${cod_grupo_empresa}`);
    const { data } = res.data;

    const {
      cod_status_pdv,
      flg_carac_especial,
      flg_mix_cad,
      flg_n_aut_cnpj_for,
      flg_n_aut_cpf_cli,
      flg_prod_diversos,
      per_bloq_custo,
      per_bloq_venda,
      per_tol_custo,
      per_tol_venda,
      tipo_custo,
      tipo_margem,
      limites,
      val_tolerancia_tes,
      tipo_cobranca_tes,
      per_juros_dia,
      qtd_dias_carencia_juros,
      flg_n_calc_juros_fds,
    } = data;

    // map do tipo limites
    const regLimites = limites.map((limite: any) => {
      return {
        cod_finalizadora: {
          value: {
            value: limite.cod_finalizadora,
            label: '',
          },
          isRequired: false,
          isInvalid: false,
        },
        des_finalizadora: {
          value: limite.des_finalizadora,
          isRequired: false,
          isInvalid: false,
        },
        val_limite: {
          value: limite.val_limite,
          isRequired: false,
          isInvalid: false,
        },
      };
    });

    setValue('limites', regLimites);
    setValue('tipo_margem', { value: tipo_margem, label: '' });
    setValue('cod_grupo_empresa', {
      value: cod_grupo_empresa,
      label: '',
    });
    setValue('cod_status_pdv', {
      value: cod_status_pdv,
      label: '',
    });
    setValue('flg_carac_especial', flg_carac_especial);
    setValue('flg_mix_cad', flg_mix_cad);
    setValue('flg_n_aut_cnpj_for', flg_n_aut_cnpj_for);
    setValue('flg_n_aut_cpf_cli', flg_n_aut_cpf_cli);
    setValue('flg_prod_diversos', flg_prod_diversos);
    setValue('per_bloq_custo', formatCurrencyAsText(per_bloq_custo));

    setValue('per_bloq_venda', formatCurrencyAsText(per_bloq_venda));
    setValue('per_tol_custo', formatCurrencyAsText(per_tol_custo));
    setValue('per_tol_venda', formatCurrencyAsText(per_tol_venda));
    setValue('tipo_custo', {
      value: tipo_custo,
      label: '',
    });
    setValue('tipo_margem', { value: tipo_margem, label: '' });
    setValue('val_tolerancia_tes', formatCurrencyAsText(val_tolerancia_tes));
    setValue('tipo_cobranca_tes', { value: tipo_cobranca_tes, label: '' });

    const regraEmpresa: FormRegraEmpresa = {
      cod_grupo_empresa: {
        value: {
          value: cod_grupo_empresa,
          label: '',
        },
        isRequired: false,
        isInvalid: false,
      },
      cod_status_pdv: {
        value: {
          value: cod_status_pdv,
          label: '',
        },
        isRequired: true,
        isInvalid: cod_status_pdv === null || cod_status_pdv === undefined,
      },
      flg_carac_especial: {
        value: flg_carac_especial,
        isRequired: false,
        isInvalid: false,
      },
      flg_mix_cad: {
        value: flg_mix_cad,
        isRequired: false,
        isInvalid: false,
      },
      flg_n_aut_cnpj_for: {
        value: flg_n_aut_cnpj_for,
        isRequired: false,
        isInvalid: false,
      },
      flg_n_aut_cpf_cli: {
        value: flg_n_aut_cpf_cli,
        isRequired: false,
        isInvalid: false,
      },
      flg_prod_diversos: {
        value: flg_prod_diversos,
        isRequired: false,
        isInvalid: false,
      },
      per_bloq_custo: {
        value: formatCurrencyAsText(per_bloq_custo),
        isRequired: false,
        isInvalid: false,
      },
      per_bloq_venda: {
        value: formatCurrencyAsText(per_bloq_venda),
        isRequired: false,
        isInvalid: false,
      },
      per_tol_custo: {
        value: formatCurrencyAsText(per_tol_custo),
        isRequired: false,
        isInvalid: false,
      },
      per_tol_venda: {
        value: formatCurrencyAsText(per_tol_venda),
        isRequired: false,
        isInvalid: false,
      },
      tipo_custo: {
        value: {
          value: tipo_custo,
          label: '',
        },
        isRequired: true,
        isInvalid: tipo_custo === null || tipo_custo === undefined,
      },
      tipo_margem: {
        value: { value: tipo_margem, label: '' },
        isRequired: true,
        isInvalid: tipo_margem === null,
      },
      Limites: {
        limites: regLimites,
        isRequired: false,
        isInvalid: false,
      },
      val_tolerancia_tes: {
        value: formatCurrencyAsText(val_tolerancia_tes),
        isInvalid: false,
        isRequired: false,
      },
      tipo_cobranca_tes: {
        value: { value: tipo_cobranca_tes, label: '' },
        isInvalid: false,
        isRequired: false,
      },
    };

    setValue('per_juros_dia', formatCurrencyAsText(per_juros_dia));
    setValue('qtd_dias_carencia_juros', qtd_dias_carencia_juros);
    setValue('flg_n_calc_juros_fds', flg_n_calc_juros_fds);

    handleFormRegraEmpresa(regraEmpresa);
    handleIsUpdate(true);
    setShowSearch(false);
  };

  const handlelojaContext = useCallback(async () => {
    const lojaTemp = loja;
    const grupoContemLojaPadrao = desLojas.some(
      (item) => Number(item) === lojaTemp.cod_loja,
    );

    if (grupoContemLojaPadrao) {
      if (lojaTemp.cod_loja)
        lojaTemp.regra_empresa.tipo_margem = Number(
          formRegraEmpresa.tipo_margem.value.value,
        );
      handleLoja(lojaTemp);
    }
  }, [desLojas, formRegraEmpresa.tipo_margem.value.value, handleLoja, loja]);

  const validaTipoMargem = useCallback(async () => {
    const res = await api.get(
      `regraempresa/valida/${formRegraEmpresa.cod_grupo_empresa.value.value}`,
    );
    const { data } = res.data;
    let validaMargem = false;

    if (Number(data[0].tot) > 0) {
      MySwal.fire({
        title: `Tipo de Margem para cálculo de preço de vendas não pode ser praticada!`,
        text: `Existe(m) ${data[0].tot} produto(s) na(s) loja(s) desse grupo de empresa com margens maiores que 99,99%. Vá até a tela de Ajuste de Margem por Produto e pesquise por todos os produtos com Margem maior  que 99,99% e faça a adequação.`,
        showConfirmButton: true,
      });
      validaMargem = true;
    }

    return validaMargem;
  }, [formRegraEmpresa.cod_grupo_empresa.value.value]);

  const validaInputsParaSubmit = (inputs: FormRegraEmpresa): boolean => {
    for (let i = 0; i < Object.keys(inputs).length; i++) {
      if (
        formRegraEmpresa[Object.keys(inputs)[i] as keyof FormRegraEmpresa]
          .isRequired &&
        formRegraEmpresa[Object.keys(inputs)[i] as keyof FormRegraEmpresa]
          .isInvalid
      ) {
        return false;
      }
    }
    return true;
  };

  async function handleSubmit() {
    const dataForm = getValues();
    try {
      const format_per_bloq_custo = transformAsCurrency(
        dataForm.per_bloq_custo || '',
      );
      const format_per_tol_custo = transformAsCurrency(
        dataForm.per_tol_custo || '',
      );
      const format_per_bloq_venda = transformAsCurrency(
        dataForm.per_bloq_venda || '',
      );
      const format_per_tol_venda = transformAsCurrency(
        dataForm.per_tol_venda || '',
      );

      if (format_per_bloq_custo < format_per_tol_custo) {
        return toast.warning(
          '[Atualização custo] A Tolerancia de aviso deve ser menor que a de bloqueio',
        );
      }
      if (format_per_bloq_venda < format_per_tol_venda) {
        return toast.warning(
          '[Atualização venda] A Tolerancia de aviso deve ser menor que a de bloqueio',
        );
      }
      //   /**
      //    * Validações
      //    */

      if (dataForm.cod_status_pdv) {
        formRegraEmpresa.cod_status_pdv.value = dataForm.cod_status_pdv;
        formRegraEmpresa.cod_status_pdv.isInvalid = false;
      }

      if (!validaInputsParaSubmit(formRegraEmpresa)) {
        return handleInicializado(true);
      }
      let tipoMargemInvalido = false;
      if (
        dataForm.tipo_margem.value === 1 ||
        dataForm.tipo_margem.value === 2 ||
        dataForm.tipo_margem.value === 3
      ) {
        tipoMargemInvalido = await validaTipoMargem();
      }

      if (!tipoMargemInvalido) {
        handleInicializado(false);

        let limites;

        if (dataForm.limites) {
          limites = dataForm.limites.map((item: any) => {
            return {
              cod_finalizadora: item.cod_finalizadora.value.value,
              val_limite: transformAsCurrency(item.val_limite.value),
            };
          });
        }

        const upsert = {
          cod_grupo_empresa: dataForm.cod_grupo_empresa.value,
          tipo_margem: dataForm.tipo_margem.value,
          tipo_custo: dataForm.tipo_custo.value,
          per_tol_custo: transformAsCurrency(dataForm.per_tol_custo || ''),
          per_bloq_custo: transformAsCurrency(dataForm.per_bloq_custo || ''),
          cod_status_pdv: dataForm.cod_status_pdv.value,
          per_tol_venda: transformAsCurrency(dataForm.per_tol_venda || ''),
          per_bloq_venda: transformAsCurrency(dataForm.per_bloq_venda || ''),
          flg_prod_diversos: dataForm.flg_prod_diversos,
          flg_mix_cad: dataForm.flg_mix_cad,
          flg_carac_especial: dataForm.flg_carac_especial,
          flg_n_aut_cpf_cli: dataForm.flg_n_aut_cpf_cli,
          flg_n_aut_cnpj_for: dataForm.flg_n_aut_cnpj_for,
          val_tolerancia_tes: transformAsCurrency(
            dataForm.val_tolerancia_tes || '',
          ),
          tipo_cobranca_tes: dataForm.tipo_cobranca_tes
            ? dataForm.tipo_cobranca_tes.value
            : null,
          per_juros_dia: transformAsCurrency(dataForm.per_juros_dia),
          qtd_dias_carencia_juros: Number(dataForm.qtd_dias_carencia_juros),
          flg_n_calc_juros_fds: dataForm.flg_n_calc_juros_fds,
          limites: dataForm.limites || [],
        };

        if (isUpdate) {
          const res = await api.put(
            `/regraempresa/${dataForm.cod_grupo_empresa.value}`,
            upsert,
          );
          const { success, message } = res.data;
          if (!success) throw new Error(message);
          toast.success(message);
          setKey('geral');
          handleClearForm();
          handlelojaContext();
          return setShowSearch(true);
        }

        const res = await api.post('/regraempresa', upsert);
        const { success, message } = res.data;
        if (!success) {
          throw new Error(message);
        }
        handlelojaContext();
        getGrupos();
        toast.success(message);
        setKey('geral');
        handleClearForm();
        setShowSearch(false);
      }
    } catch (error: any) {
      toast.warning(error.message);
    }
  }

  async function handleDelete() {
    try {
      const res = await api.delete(
        `/regraempresa/${formRegraEmpresa.cod_grupo_empresa.value.value}`,
      );
      const { success, message } = res.data;
      if (!success) {
        throw new Error(message);
      }
      setShowSearch(true);
      handleClearForm();
      setKey('geral');
      toast.success(message);
    } catch (error: any) {
      toast.warning(error.message);
    }
  }

  function handleCancel() {
    handleClearForm();
    setShowSearch(true);
    handleInicializado(false);
    handleIsUpdate(false);
  }

  return (
    <Container>
      {showSearch && (
        <Search codTela={26} newData={newData} onRowClick={onRowClick} />
      )}

      {!showSearch && (
        <FormDefault
          codTela={26}
          title="Regras de Empresas"
          codigoRegistro={[
            {
              value: formRegraEmpresa.cod_grupo_empresa.value.value,
              des_campo: 'Código',
            },
          ]}
          onSave={handleSubmit}
          onCancel={handleCancel}
          isUpdate={isUpdate}
          onNew={newData}
          onDelete={handleDelete}
          onClearFields={newData}
          onReturnSearch={handleCancel}
        >
          <Row className="mb-4">
            <Col sm={12} md={12}>
              <InputSelect
                label="Grupo"
                onChange={(value: any) => {
                  setValue('cod_grupo_empresa', value);
                  handleFormRegraEmpresa({
                    ...formRegraEmpresa,
                    cod_grupo_empresa: {
                      ...formRegraEmpresa.cod_grupo_empresa,
                      value,
                      isInvalid: value.value === undefined,
                    },
                  });
                }}
                options={grupoEmpresas}
                noOptionsMessage="Nenhum novo grupo de empresa sem regras cadastrada, pesquise e edite um já existente."
                isRequired={formRegraEmpresa.cod_grupo_empresa.isRequired}
                setInvalid={formRegraEmpresa.cod_grupo_empresa.isInvalid}
                iniInicializado={inicializado}
                isDisabled={isUpdate}
                value={
                  grupoEmpresas.find((item: Select) => {
                    return (
                      item.value ===
                      formRegraEmpresa.cod_grupo_empresa.value.value
                    );
                  }) ?? {
                    value: undefined,
                    label: '- Selecione um Grupo -',
                  }
                }
              />
            </Col>
          </Row>
          <Tabs
            id="controlled-regra_de_loja"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="geral" title="Geral">
              <AbaGeral />
            </Tab>
            {!disabled && (
              <Tab eventKey="sistema" title="Sistema">
                <AbaSistema />
              </Tab>
            )}
            <Tab eventKey="financeiro" title="Financeiro">
              <AbaFinanceiro />
            </Tab>
          </Tabs>
        </FormDefault>
      )}
    </Container>
  );
};

export default RegraDeEmpresaContent;
