import React, { useEffect, useState } from 'react';
import { DataGrid, GridColumns, GridRowParams } from '@material-ui/data-grid';
import { LimiteCredito } from '~/pages/RegraDeEmpresa/protocols';
import { useRegraEmpresa } from '~/pages/RegraDeEmpresa/RegraDeEmpresaContext';
import { ButtonRow } from './styles';
import { GoPencil } from 'react-icons/go';
import { MdDeleteForever } from 'react-icons/md';
import { FormLimiteCredito } from '~/pages/RegraDeEmpresa/protocols';
import { Col } from 'react-bootstrap';
import { formatCurrencyAsText } from '~/utils/functions';

export const Table: React.FC = () => {
  const [limites, setLimites] = useState<LimiteCredito[]>([]);
  const {
    formRegraEmpresa,
    handleFormRegraEmpresa,
    isUpdate,
    formLimiteCredito,
    handleFormLimiteCredito,
    formTela: { setValue },
  } = useRegraEmpresa();

  useEffect(() => {
    const regs = formRegraEmpresa.Limites.limites.map((limite) => {
      return {
        id: limite.cod_finalizadora.value.value,
        des_finalizadora: limite.des_finalizadora.value,
        val_limite: formatCurrencyAsText(limite.val_limite.value),
      };
    });
    setLimites(regs);
  }, [formRegraEmpresa.Limites.limites]);

  const limiteColumns: GridColumns = [
    {
      field: 'des_finalizadora',
      headerName: 'Finalizadora',
      width: 600,
    },
    {
      field: 'val_limite',
      headerName: 'Valor',
      width: 200,
    },
    {
      field: '',
      headerName: 'Ações',
      sortable: false,
      width: 180,
      flex: 1,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      // disableClickEventBubbling: true,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        const onEdit = () => {
          // setLimite(row);
          // setSelectEntidade({
          //   id: row.cod_entidade,
          //   label: row.des_entidade
          // })
          const findFormLimiteCredito = formRegraEmpresa.Limites.limites.find(
            (limite) => limite.cod_finalizadora.value.value === row.id,
          );
          if (findFormLimiteCredito) {
            // handleFormLimiteCredito(findFormLimiteCredito);
            handleFormLimiteCredito({
              ...formLimiteCredito,
              cod_finalizadora: {
                ...formLimiteCredito.cod_finalizadora,
                value: findFormLimiteCredito.cod_finalizadora.value,
                isInvalid:
                  findFormLimiteCredito.cod_finalizadora.value.value ===
                  undefined,
              },
              des_finalizadora: {
                ...formLimiteCredito.des_finalizadora,
                value: findFormLimiteCredito.des_finalizadora.value,
              },
              val_limite: {
                ...formLimiteCredito.val_limite,
                value: findFormLimiteCredito.val_limite.value,
                isInvalid:
                  parseFloat(findFormLimiteCredito.val_limite.value) === 0,
              },
            });
          }
        };
        const onDelete = () => {
          const filtered = formRegraEmpresa.Limites.limites.filter(
            (val: FormLimiteCredito) => {
              return val.cod_finalizadora.value.value !== row.id;
            },
          );
          // handleClearForm();
          setValue('limites', filtered);
          handleFormRegraEmpresa({
            ...formRegraEmpresa,
            Limites: {
              ...formRegraEmpresa.Limites,
              limites: filtered,
            },
          });
        };

        return (
          <>
            <Col style={{ display: 'flex', justifyContent: 'center' }}>
              <ButtonRow type="button" onClick={onEdit}>
                <GoPencil size={20} style={{ color: '#72ab90' }} /> Editar
              </ButtonRow>
              <ButtonRow type="button" onClick={onDelete}>
                <MdDeleteForever size={20} style={{ color: '#e63c3c' }} />{' '}
                Excluir
              </ButtonRow>
            </Col>
          </>
        );
      },
    },
  ];
  return (
    <>
      <div
        className="col-sm-12 col-md-12"
        style={{
          height: 380,
          width: '100%',
          marginTop: '16px',
        }}
      >
        <DataGrid
          rows={limites}
          columns={limiteColumns}
          pageSize={5}
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          disableSelectionOnClick
          localeText={{
            noRowsLabel: '',
          }}
        />
      </div>
    </>
  );
};
