import { Select } from './Select';

export const tipoMargens: Select[] = [
  {
    value: 0,
    label: 'Mark-Up',
  },
  {
    value: 1,
    label: 'Lucro',
  },
  {
    value: 2,
    label: 'Mark-Down',
  },
  {
    value: 3,
    label: 'Contribuição',
  },
];
