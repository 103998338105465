import { Select } from './Select';
import { FormLimiteCredito } from './LimiteCredito';

export interface FormRegraEmpresa {
  cod_grupo_empresa: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  tipo_margem: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  tipo_custo: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  per_tol_custo: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  per_bloq_custo: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  per_tol_venda: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  per_bloq_venda: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  cod_status_pdv: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_prod_diversos: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_mix_cad: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_carac_especial: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_n_aut_cpf_cli: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_n_aut_cnpj_for: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  Limites: {
    limites: FormLimiteCredito[];
    isInvalid: boolean;
    isRequired: boolean;
  };
  val_tolerancia_tes: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  tipo_cobranca_tes: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
}

export const formRegraEmpresaBlank: FormRegraEmpresa = {
  cod_grupo_empresa: {
    value: { label: '', value: undefined },
    isInvalid: true,
    isRequired: true,
  },
  tipo_margem: {
    value: { label: '', value: undefined },
    isInvalid: true,
    isRequired: true,
  },
  tipo_custo: {
    value: { label: '', value: undefined },
    isInvalid: true,
    isRequired: true,
  },
  per_tol_custo: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  per_bloq_custo: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  per_tol_venda: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  per_bloq_venda: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  cod_status_pdv: {
    value: { label: '', value: undefined },
    isInvalid: true,
    isRequired: true,
  },
  flg_prod_diversos: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  flg_mix_cad: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  flg_carac_especial: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  flg_n_aut_cpf_cli: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  flg_n_aut_cnpj_for: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  Limites: {
    limites: [],
    isInvalid: false,
    isRequired: false,
  },
  val_tolerancia_tes: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  tipo_cobranca_tes: {
    value: { label: '', value: undefined },
    isInvalid: false,
    isRequired: false,
  },
};
