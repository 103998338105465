import React, {
  createContext,
  useState,
  ReactNode,
  useCallback,
  useContext,
} from 'react';
import { useForm } from 'react-hook-form';
import { schema } from './abas/Financeiro/schema';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormRegraEmpresa,
  formRegraEmpresaBlank,
  FormLimiteCredito,
  formLimiteCreditoBlank,
  UseFormProps,
} from '~/pages/RegraDeEmpresa/protocols';

type RegrasDeEmpresaContextData = {
  // Select: Select[];
  // GrupoEmpresas: GrupoEmpresa[];
  // initLoja: boolean;
  // handleClearForm: () => void;
  // onChangeInit: (value: boolean) => void;
  inicializado: boolean;
  handleInicializado: (value: boolean) => void;
  formRegraEmpresa: FormRegraEmpresa;
  handleFormRegraEmpresa: (form: FormRegraEmpresa) => void;
  formLimiteCredito: FormLimiteCredito;
  handleFormLimiteCredito: (form: FormLimiteCredito) => void;
  isUpdate: boolean;
  handleIsUpdate: (value: boolean) => void;
  handleClearForm: () => void;
  handleClearFormLimite: () => void;
  formTela: UseFormProps;
};

export const RegrasDeEmpresaContext = createContext(
  {} as RegrasDeEmpresaContextData,
);
interface RegrasDeEmpresaContextProviderProps {
  children: ReactNode;
}

export function RegraEmpresaContextProvider({
  children,
}: RegrasDeEmpresaContextProviderProps): JSX.Element {
  const [isUpdate, setIsUpdate] = useState(false);
  const [inicializado, setInicializado] = useState(false);

  const [formRegraEmpresa, setFormRegraEmpresa] = useState<FormRegraEmpresa>(
    formRegraEmpresaBlank,
  );

  const {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
    reset,
    setFocus,
    clearErrors,
    setError,
    resetField,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const formTela: UseFormProps = {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
    reset,
    setFocus,
    clearErrors,
    setError,
    resetField,
  };

  const handleIsUpdate = useCallback((value: boolean) => {
    setIsUpdate(value);
  }, []);

  const handleInicializado = useCallback((value: boolean) => {
    setInicializado(value);
  }, []);
  const handleFormRegraEmpresa = useCallback((form: FormRegraEmpresa) => {
    setFormRegraEmpresa(form);
  }, []);

  const [formLimiteCredito, setFormLimiteCredito] =
    React.useState<FormLimiteCredito>(formLimiteCreditoBlank);

  const handleFormLimiteCredito = useCallback((form: FormLimiteCredito) => {
    setFormLimiteCredito(form);
  }, []);

  const handleClearForm = useCallback(() => {
    const formRegraEmpresaBlank2: FormRegraEmpresa = {
      cod_grupo_empresa: {
        value: { label: '', value: undefined },
        isInvalid: true,
        isRequired: true,
      },
      tipo_margem: {
        value: { label: '', value: undefined },
        isInvalid: true,
        isRequired: true,
      },
      tipo_custo: {
        value: { label: '', value: undefined },
        isInvalid: true,
        isRequired: true,
      },
      per_tol_custo: {
        value: '',
        isInvalid: false,
        isRequired: false,
      },
      per_bloq_custo: {
        value: '',
        isInvalid: false,
        isRequired: false,
      },
      per_tol_venda: {
        value: '',
        isInvalid: false,
        isRequired: false,
      },
      per_bloq_venda: {
        value: '',
        isInvalid: false,
        isRequired: false,
      },
      cod_status_pdv: {
        value: { label: '', value: undefined },
        isInvalid: true,
        isRequired: true,
      },
      flg_prod_diversos: {
        value: false,
        isInvalid: false,
        isRequired: false,
      },
      flg_mix_cad: {
        value: false,
        isInvalid: false,
        isRequired: false,
      },
      flg_carac_especial: {
        value: false,
        isInvalid: false,
        isRequired: false,
      },
      flg_n_aut_cpf_cli: {
        value: false,
        isInvalid: false,
        isRequired: false,
      },
      flg_n_aut_cnpj_for: {
        value: false,
        isInvalid: false,
        isRequired: false,
      },
      Limites: {
        limites: [],
        isInvalid: false,
        isRequired: false,
      },
      val_tolerancia_tes: {
        value: '',
        isInvalid: false,
        isRequired: false,
      },
      tipo_cobranca_tes: {
        value: { label: '', value: undefined },
        isInvalid: false,
        isRequired: false,
      },
    };
    reset();
    setFormRegraEmpresa(formRegraEmpresaBlank2);
    setFormLimiteCredito(formLimiteCreditoBlank);
  }, [reset]);

  const handleClearFormLimite = () => {
    setFormLimiteCredito(formLimiteCreditoBlank);
  };

  return (
    <RegrasDeEmpresaContext.Provider
      value={{
        formRegraEmpresa,
        handleFormRegraEmpresa,
        isUpdate,
        handleIsUpdate,
        handleClearForm,
        handleFormLimiteCredito,
        formLimiteCredito,
        handleClearFormLimite,
        inicializado,
        handleInicializado,
        formTela,
      }}
    >
      {children}
    </RegrasDeEmpresaContext.Provider>
  );
}

export const useRegraEmpresa = (): RegrasDeEmpresaContextData => {
  return useContext(RegrasDeEmpresaContext);
};
