import { Select } from './Select';

export const tipoCobranca: Select[] = [
  {
    value: 0,
    label: 'Quebra',
  },
  {
    value: 1,
    label: 'Sobra',
  },
  {
    value: 2,
    label: 'Ambos',
  },
];
