import { Select } from './Select';

export const tipoCustos: Select[] = [
  {
    value: 0,
    label: 'Reposição',
  },
  {
    value: 1,
    label: 'Fiscal',
  },
  {
    value: 2,
    label: 'S. Imposto',
  },
  {
    value: 3,
    label: 'S. ICMS',
  },
];
