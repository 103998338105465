import React from 'react';
import RegraDeEmpresaContent from './RegraDeEmpresaContent';
import { RegraEmpresaContextProvider } from './RegraDeEmpresaContext';

const RegraDeEmpresa: React.FC = () => {
  return (
    <RegraEmpresaContextProvider>
      <RegraDeEmpresaContent />
    </RegraEmpresaContextProvider>
  );
};

export default RegraDeEmpresa;
