import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .calculate-container {
    display: flex;
    align-items: center;
  }

  .space {
    margin-right: 10px;
  }

  .calculate-container > * {
    margin-right: 20px;
  }
`;
